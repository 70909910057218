import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Box, Icon, LinkBox, Section, Em, Span } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"kontakti"} />
		<Helmet>
			<title>
				Trailblaze24
			</title>
			<meta name={"description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:title"} content={"Trailblaze24"} />
			<meta property={"og:description"} content={"Trailblaze24 пропонує оренду квадроциклів для тих, хто цінує свободу, екстрим та незабутні емоції."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/BRP-Can-Am-Outlander-MAX-570.png?v=2024-11-27T09:00:38.548Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 0px 0" background="--color-dark" quarkly-title="Footer-13" sm-padding="50px 0 50px 0">
			<Box
				min-width="100px"
				min-height="100px"
				padding="160px 30px 80px 30px"
				background="linear-gradient(180deg,rgba(2, 17, 8, 0.83) 0%,--color-darkL2 99%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/6745953f28b5e30023ae7079/images/kvadro-3.jpg?v=2024-11-27T09:00:38.516Z) center/cover repeat scroll padding-box"
				display="flex"
				justify-content="space-around"
				align-items="center"
				margin="0px 0px 80px 0px"
				md-flex-direction="column"
				md-align-items="stretch"
				md-margin="0px 0px 50px 0px"
				md-padding="30px 30px 30px 30px"
				sm-padding="20px 15px 20px 15px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					md-margin="0px 0px 30px 0px"
					md-width="100%"
					sm-margin="0px 0px 18px 0px"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 42px/1.2 --fontFamily-sansHelvetica"
						color="--light"
						md-margin="0px 0px 15px 0px"
						sm-margin="0px 0px 12px 0px"
					>
						Ми знаходимося за адресою{" "}
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							вулиця Юності, 41А, Микитинці, Івано-Франківська область
						</Strong>
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 400 42px/1.2 --fontFamily-sansHelvetica"
						color="--light"
						md-margin="0px 0px 15px 0px"
						sm-margin="0px 0px 12px 0px"
					>
						Телефонуйте за номером
						<br />
						0977183394
						<br />
					</Text>
					<Text margin="0px 0px 0px 0px" color="--light" font="normal 300 20px/1.5 --fontFamily-sans">
						З понеділка по неділю
						<br />
						9:00 - 18:00
					</Text>
				</Box>
				<LinkBox
					flex-direction="row"
					padding="25px 35px 25px 35px"
					border-width="2px"
					border-style="solid"
					border-color="--color-light"
					background="rgba(247, 251, 255, 0.12)"
					sm-padding="10px 15px 10px 15px"
					href="tel:0977183394"
				>
					<Icon
						category="md"
						icon={MdPhone}
						size="24px"
						color="--light"
						margin="0px 8px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="--light">
						0977183394
					</Text>
				</LinkBox>
			</Box>
		</Section>
		<Components.Footernew>
			<Override slot="text1">
				<Span
					font-weight="normal"
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Em
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Телефонуйте за номером
						<br />
						0977183394
						<br />
						<br />
						<br />
						вулиця Юності, 41А, Микитинці, Івано-Франківська область
					</Em>
				</Span>
			</Override>
		</Components.Footernew>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"673f21f141a0cf0024a5b026"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});